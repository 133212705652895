<template>
  <v-row class="ma-0">
    <v-col class="d-flex flex-column white pt-2">
      <form ref="form" @submit.prevent="fund" novalidate>
        <v-row class="mx-2 mt-5">
          <v-col>
            <s-select
              label="Currency"
              name="currency"
              filled
              required
              :items="currencies"
              v-model="currency"
              :error-messages="getErrors('currency', $v.currency, 'currency')"
              @blur="$v.currency.$touch()"
            />
            <s-text-field
              label="Amount"
              name="amount"
              filled
              required
              v-model="amount"
              :error-messages="getErrors('amount', $v.amount, 'Amount')"
              @blur="$v.amount.$touch()"
            />
            <s-btn
              type="submit"
              color="primary"
              elevation="0"
              :loading="isLoading"
            >
              Submit
            </s-btn>
          </v-col>
        </v-row>
      </form>
    </v-col>
  </v-row>
</template>
<script>
import getErrors from '@/mixins/vuelidateErrors'
import { required, minValue, numeric } from 'vuelidate/lib/validators'
export default {
  name: 'FundWallet',
  mixins: [getErrors],
  data() {
    return {
      currencies: ['NGN'],
      amount: '',
      currency: 'NGN',
      isLoading: false,
    }
  },
  methods: {
    fund() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.isLoading = true
      const koboAmount = parseInt(this.amount) * 100
      this.$store
        .dispatch('billing/fund', {
          currency: this.currency,
          amount: koboAmount,
        })
        .then((resp) => {
          this.openPaymentModal(resp.data.checkout_url)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    goBack() {
      this.$router.push({ name: 'billing' })
    },
    openPaymentModal(destination) {
      let modal = null
      window.oauthCallback = () => {
        this.$emit('refresh')
        modal.close()
        this.goBack()
      }
      modal = window.open(
        destination,
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=500,height=500'
      )
    },
  },
  validations: {
    currency: { required },
    amount: {
      required,
      minValue: minValue(100),
      numeric,
    },
  },
}
</script>
