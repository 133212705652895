<template>
  <v-container>
    <s-card class="stats-card pa-4 pa-lg-6 overflow-hidden mt-3 mb-10">
      <div class="d-flex align-center mt-4">
        <v-row>
          <v-col cols="4">
            <div class="d-flex">
              <s-icon name="coins" class="mr-4" />
              <s-text weight="medium" size="md" color="gray">
                Wallet Balance
              </s-text>
            </div>
            <div class="pl-10">
              <s-text
                weight="bold"
                size="m-lg"
                color="success"
                v-if="!isLoading"
              >
                ₦
                <ICountUp
                  :delay="countup.delay"
                  :endVal="walletBalance"
                  :options="countup.options"
                />
              </s-text>
              <v-skeleton-loader
                v-else
                class="flex ma-0"
                width="300"
                type="card-heading"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex">
              <s-icon name="coins" class="mr-4" />
              <s-text weight="medium" size="md" color="gray">
                Promo Wallet
              </s-text>
            </div>
            <div class="pl-10">
              <s-text weight="bold" size="m-lg" v-if="!isLoading">
                ₦
                <ICountUp
                  :delay="countup.delay"
                  :endVal="lifetimeWallet"
                  :options="countup.options"
                />
              </s-text>
              <v-skeleton-loader
                v-else
                class="flex ma-0"
                width="300"
                type="card-heading"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </s-card>

    <s-card class="pa-0 pb-2 clear mb-10">
      <section
        class="domain-rules__header bottom-thin-border pl-10 pr-10 py-4 d-none d-lg-flex justify-space-between align-center"
      >
        <h3 class="capitalize">Transactions</h3>
      </section>
      <loader v-if="isLoadingTx" :message="'Getting transactions ...'" />
      <div v-else>
        <empty-state v-if="tx.length === 0" message="No transactions yet." />
        <section v-else>
          <v-row
            class="customers__table__header table__header pl-10 pr-10 pt-3 ma-0 d-none d-lg-flex"
            no-gutters
          >
            <v-col cols="4">
              <s-text weight="medium" color="gray" size="sm" class="uppercase">
                Transaction Summary
              </s-text>
            </v-col>
            <v-col cols="4" class="pl-2">
              <s-text weight="medium" color="gray" size="sm" class="uppercase">
                Amount (₦)
              </s-text>
            </v-col>
            <v-col cols="4" class="pl-3">
              <s-text weight="medium" color="gray" size="sm" class="uppercase">
                Status
              </s-text>
            </v-col>
          </v-row>
          <section class="overflow-hidden">
            <v-row
              class="pl-10 py-3 pr-10 top-thin-border my-0 list_item"
              v-for="(t, index) in tx"
              :key="index"
            >
              <v-col cols="4" class="d-flex align-center ma-0 pr-10 pr-lg-0">
                <s-icon
                  name="card-single"
                  color="grayText"
                  class="d-block list_item__icon mr-2"
                />
                <s-text weight="medium" class="pl-8 pl-lg-0"> Paystack </s-text>
              </v-col>

              <v-col cols="4" class="align-center ma-0 d-none d-lg-flex">
                <s-text weight="medium">
                  {{ (t.amount / 100) | currency }}
                </s-text>
              </v-col>

              <v-col cols="4" class="align-center ma-0 d-none d-lg-flex">
                <s-icon
                  :name="getStatusIcon(t.status)"
                  class="d-block list_item__icon mr-2"
                />
                <s-text :color="getStatusColor(t.status)">
                  {{ getStatus(t.status) }}
                </s-text>
              </v-col>
            </v-row>
          </section>

          <!-- <section class="d-flex pt-5 pb-5">
            <s-btn
              color="primary"
              class="ma-auto"
              elevation="0"
              width="300px"
              v-if="showLoadMore"
              :loading="isWorking"
              @click="loadMore"
            >
              Load more
            </s-btn>
          </section> -->
        </section>
      </div>
    </s-card>

    <s-dialog v-model="showModal" persistent isModal :title="'Fund Wallet'">
      <fund-wallet v-if="showModal" @refresh="getWalletBalance()" />
    </s-dialog>
  </v-container>
</template>

<script>
import ICountUp from 'vue-countup-v2'
import { mapGetters } from 'vuex'
import FundWallet from '@/views/billing/FundWallet'
import Loader from '@/components/cards/Loader'
import EmptyState from '@/components/cards/EmptyState'
export default {
  name: 'Billing',
  components: {
    ICountUp,
    'fund-wallet': FundWallet,
    'empty-state': EmptyState,
    loader: Loader,
  },
  computed: {
    ...mapGetters({
      tx: 'billing/tx',
      org: 'org/org',
    }),
    walletBalance() {
      return this.org.wallet
    },
    lifetimeWallet() {
      return this.org.promo_wallet
    },
  },
  data() {
    return {
      showModal: this.$route.meta.showModal,
      valid: true,
      isLoading: false,
      isLoadingTx: false,
      filterBy: 'oneDayAgo',
      countup: {
        delay: 500,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
        },
      },
    }
  },
  filters: {
    currency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
      }).format(value)
    },
  },
  methods: {
    getWalletBalance() {
      this.isLoading = true
      this.$store.dispatch('billing/getWalletBalance').finally(() => {
        this.isLoading = false
      })
    },
    getTx() {
      this.isLoadingTx = true
      this.$store.dispatch('billing/getTx').finally(() => {
        this.isLoadingTx = false
      })
    },
    getStatus(status) {
      if (status === undefined || status === 0) return 'Pending'
      if (status === 1) return 'Success'
      if (status === 2) return 'Failed'
      return 'Unknown'
    },
    getStatusColor(status) {
      if (status === undefined || status === 0) return 'grayTextAlt'
      if (status === 1) return 'success'
      if (status === 2) return 'primary'
      return 'Unknown'
    },
    getStatusIcon(status) {
      if (status === undefined || status === 0) return 'pending'
      if (status === 1) return 'double-check'
      if (status === 2) return 'stop'
      return 'Unknown'
    },
  },
  watch: {
    '$route.meta'({ showModal }) {
      this.showModal = showModal
    },
  },
  created() {
    this.getWalletBalance()
    this.getTx()
  },
}
</script>

<style lang="scss" scoped>
.stats-card {
  height: 165px;
}
::v-deep {
  .v-skeleton-loader__card-heading {
    background: none !important;
  }

  .v-skeleton-loader__heading {
    margin: 16px 0;
    border-radius: 20px;
    height: 40px;
    width: 70%;
  }
}
</style>
